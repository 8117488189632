import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Structural Foam Molding",
  "description": "For manufacturing large plastic parts.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Structural foam molding may sound like the end product is a soft, foam-like substance. This is wrong. Although it’s not as popular as injection molding, structural foam molding can produce very large parts under low pressure, resulting in thicker wall sections compared to injection molding. `}</p>
    <p>{`Structural foam molding works by either adding a chemical blowing agent or air into the molten plastic resin during the molding process. Either way, this creates air-filled bubbles inside of the plastic structure. The result is reduced part weight, thus decreasing the amount of resin used to create the product. Generally, PS, SAN, ABS, PE, and PPR plastic is used in the process.`}</p>
    <p>{`The reason why we don’t use structural foam molding for all injection molding tasks is due to the rough textures obtained in this process. This may require additional polishing and painting which isn’t good for scalability. Although it is not a commonly used process in manufacturing IoT hardware, it is something we offer, typically for large industrial products.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      